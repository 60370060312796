<template>
  <div class="flex-column batchDel_main">
    <pageHead class="messageMainView_head" title="添加圈成员" />
    <van-row class="search_box">
      <searchBox placeholder="搜索成员进行添加" @input="handleInput" :disabled="false"></searchBox>
    </van-row>
    <van-cell-group>
      <van-cell title="按企业架构添加" @click="openOrgDialog" is-link />
    </van-cell-group>
    <div class="delete-content"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore">
      <van-checkbox-group
        @change="handleChange"
        v-if="vanSwipeList.length > 0"
        v-model="selectIdList"
        :max="100">
        <van-checkbox
          class="delete-item"
          checked-color="#FE5800"
          v-for="item in vanSwipeList"
          :key="item.userId"
          :name="item.userId">
          <div class="user-wrap">
            <AvatarFrame class="wrap-left" :user-info="item"></AvatarFrame>
            <div class="wrap-right">
              <div class="user-name" v-html="item.username"></div>
              <div class="user-dept">{{ item.deptStr }}</div>
            </div>
          </div>
        </van-checkbox>
      </van-checkbox-group>

      <div class="skeleton-box" v-if="!isLoaded && vanSwipeList.length === 0">
        <van-skeleton title :row="10" />
      </div>

      <!-- 无数据展示 -->
      <template v-if="isLoaded && vanSwipeList.length === 0">
        <div class="hotCircle_noData">
          <div class="noData_box">
            <div class="noData_img"></div>
            <span>{{ noDataInfo.title1 || '暂未数据' }}</span>
          </div>
        </div>
      </template>

      <footer class="footer">
        <div class="flex-row-space-between delete-list">
          <div class="flex-row-h-center left-list">
            <van-badge class="flex-center-column" style="" v-for="item in selectList" :key="item.id">
              <AvatarFrame class="wrap-left" :user-info="item"></AvatarFrame>
              <p class="user-name">{{ item.username }}</p>
              <template #content>
                <van-icon @click="handleDelete(item)" name="cross" class="badge-icon" />
              </template>
            </van-badge>
          </div>
          <div class="flex-center-row delete-button"
            @click="handleSubmit"
            :class="{baColor: selectList.length > 0}">
            <span>确认添加</span>
            <span v-if="selectList.length > 0">({{ selectList.length }})</span>
          </div>
        </div>
      </footer>
      <!--todo  从组织中选择添加成员-->
      <van-popup
        round
        v-model="showAddMember"
        position="bottom"
        :style="{ height: '90%' }">
        <Organization
          ref="orgRef"
          v-if="showAddMember"
          @close="handleClose"
          @submit="handleAddOrgMember"></Organization>
      </van-popup>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import pageHead from '@/components/pageHead'
import Organization from '@/components/organization'
import searchBox from '@/components/searchBox'
import {batchAddMember} from "@/api/circle";
import {getUserByNameNew} from "@/api/suser";

export default {
  name: 'batchDel',
  components: {
    pageHead,
    AvatarFrame,
    searchBox,
    Organization
  },
  data () {
    return {
      showAddMember: false, // 添加成员弹框
      finished: false,
      selectIdList: [],
      selectList: [], // 选择的数据
      vanSwipeList: [],
      noDataInfo: {
        title1: '暂无相关人员'
      },
      paginationData: {
        pageNum: 1,
        pageSize: 10,
        username: ''
      },
      isLoaded: true,
    }
  },
  // created() {
  //   if (this.$route.query.circleId) {
  //     this.paginationData.circleId = this.$route.query.circleId
  //     this.getMemberList()
  //   }
  // },
  methods: {
    // 添加成员
    openOrgDialog () {
      this.showAddMember = true
    },
    handleAddOrgMember (list) {
      console.log(list)
      if (list.length === 0) {
        return
      }
      let isList = []
      list.forEach(item => {
        isList.push(item.id)
      })
      console.log(isList)
      this.batchAddMember(isList)
    },
    // 确认添加
    batchAddMember (idList) {
      if (idList.length === 0) {
        return
      }
      batchAddMember({
        userIds: idList,
        circleId: this.$route.query.circleId
      }).then(() => {
        this.$toast({
          message: '添加成功',
          position: 'top'
        })
        this.$refs.orgRef.clearSelectMemberList()
        this.initData()
      }).catch(err => {
        console.log(err.response.data.msg)
        this.$toast({
          message: err.response.data.msg + ',请勿重复添加！',
          position: 'top'
        })
      })
    },
    handleClose () {
      this.showAddMember = false
    },
    initData () {
      // this.paginationData.pageNum = 1
      this.selectIdList = []
      this.selectList = []
    },
    // 选项发生变化
    handleChange (value) {
      if (value.length > 0) {
        console.log(value)
        console.log(this.selectIdList)
        this.selectList = []
        this.selectIdList.forEach(idItem => {
          this.selectList = this.selectList.concat(this.vanSwipeList.filter(item => item.userId === idItem))
        })
        console.log(this.selectList)
      }
    },
    // 删除不需要删除的某一项
    handleDelete (item) {
      console.log(item)
      this.selectList = this.selectList.filter(row => row.userId !== item.userId)
      this.selectIdList = this.selectIdList.filter(row => row !== item.userId)
    },
    // 加载更多
    loadMore () {
      console.log(111)
      if (!this.finished && this.vanSwipeList.length > 0) {
        this.loading = true
        this.paginationData.pageNum++
        this.getMemberList()
      }
    },
    // 分页获取圈成员列表
    async getMemberList (type) {
      try {
        if (!this.paginationData.username) {
          delete this.paginationData.username
        }
        const res = await getUserByNameNew(this.paginationData) || {}
        const {records} = res || []
        if (records.length < 10) {
          this.finished = true
        }
        const list = records.map(item => {
          return {
            ...item,
            ...item.userBasicVO
          }
        })
        if (type === 'reset') {
          this.vanSwipeList = list
        } else {
          this.vanSwipeList = this.vanSwipeList.concat(list)
        }
        this.vanSwipeList = this.vanSwipeList.filter(item => item.identity !== 1)
      } finally {
        this.isLoaded = true
      }
    },
    // 确认添加
    handleSubmit () {
      if (this.selectIdList.length === 0) {
        return
      }
      batchAddMember({
        userIds: this.selectIdList,
        circleId: this.$route.query.circleId
      }).then(() => {
        this.$toast({
          message: '添加成功',
          position: 'top'
        })
        this.initData()
      }).catch(err => {
        console.log(err.response.data.msg)
        this.$toast({
          message: err.response.data.msg + ',请勿重复添加！',
          position: 'top'
        })
      })
    },
    // 回调输入框
    handleInput (value) {
      this.selectList = []
      this.selectIdList = []
      if (value) {
        this.finished = false
        this.paginationData.username = value
        this.paginationData.pageNum = 1
        this.getMemberList('reset')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.batchDel_main {
  background: #fff;
  height: 100%;

  .delete-content {
    overflow-y: auto;
    flex: 1;
    padding-bottom: 110px;
  }

  .skeleton-box {
    margin-top: 20px;
  }

  .user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    .wrap-left {
      display: flex;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    .wrap-right {
      line-height: 22px;
      margin-left: 10px;

      .user-name {
        font-size: 14px;
        font-weight: 500;
        text-align: LEFT;
      }

      .user-dept {
        font-size: 14px;
        color: #6d7278;
      }
    }
  }

  .delete-item {
    padding: 16px 0;
    margin: 0 24px;
    border-bottom: 1px solid #f4f4f4;

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }
  }

  .member-item {
    border-bottom: 1px solid #f4f4f4;

    .left {
      padding: 16px 0;
      margin-left: 24px;

      .check-box {
        width: 21px;
        height: 21px;
        margin-right: 10px;
        box-sizing: border-box;
      }

      .checkbox_icon {
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px solid #dcdcdc;
      }

      .active_icon {
        background: url('~@/assets/imgs/circle/Slice43@2x.png');
        background-size: 100%;
      }
    }

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }

    .delete-button {
      height: 100%;
    }
  }

  //.van_cell_box {
  //  ::v-deep .van-cell {
  //    padding-top: 16px;
  //    padding-bottom: 16px;
  //    border-bottom: 1px solid #f4f4f4;
  //    font-size: 16px;
  //    color: #000000;
  //  }
  //}

  .circleList {
    overflow: scroll;
    width: 230px;
    float: left;

    .circleLi {
      display: flex;
    }

    .item_cir:first-child {
      margin-left: 0;
    }

    .item_cir {
      margin-right: 5px;

      .cir_title {
        font-size: 12px;
        color: #333333;
        text-indent: 6px;
        margin-top: 6px;
        letter-spacing: 0;
      }

      .van_image_box {
        position: relative;

        .van_image_lsBox {
          position: absolute;
          right: 0;
          z-index: 88;
          top: -10px;

          .van_image_ls {
            display: inline-block;
            width: 14px;
            height: 14px;
            color: #fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.75);
            position: relative;

            &::after {
              content: '-';
              position: absolute;
              left: 2px;
              top: -9px;
            }
          }
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }
  }

  .uploader_box {
    padding: 18px 15px 0 15px;
    overflow: hidden;

    .submitAdd {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #f6f6f6;
      border-radius: 100px;

      font-size: 13px;
      text-align: CENTER;
      color: #6d7278;
      line-height: 35px;
      float: right;
    }

    .submitAdd2 {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #FE5800;
      border-radius: 100px;
      font-size: 13px;
      text-align: CENTER;
      color: #fff;
      line-height: 35px;
      float: right;
    }
  }

  .search_box {
    padding-top: 12px;

    ::v-deep .van-search__content {
      height: 41px;
      padding-left: 15px;
    }

    ::v-deep .van-cell {
      line-height: 31px;
    }
  }

  ::v-deep .van-search__content {
    border-radius: 70px;
  }

  .cancal {
    position: relative;
    left: 10px;
    top: 3px;

    .cancal_title {
      font-size: 14px;
      text-align: center;
      color: #000000;
      line-height: 14px;
    }
  }

  .vant_List {
    overflow: scroll;

    ::v-deep {
      .van-swipe-cell__wrapper {
        display: flex;
        border-bottom: 1px solid #f4f4f4;
      }

      .van-card__title {
        padding-left: 0;
      }

      .van-card {
        padding-left: 10px;
        padding: 0 0 0 10px;
      }

      .van-card:not(:first-child) {
        margin-top: 0;
      }

      .van-swipe-cell:first-child {
        margin-top: 0;
      }

      .van-swipe-cell {
        margin-top: 16px;
        padding: 0 15px;
      }
    }


  }

  .hotCircle_noData {
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .noData_box {
      margin-bottom: 16px;

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      font-size: 12px;
      color: #6d7278;
    }
  }

  footer {
    width: 100%;
    height: 108px;
    position: fixed;
    background: #fff;
    border-top: 1px solid #f4f4f4;
    bottom: 0;

    .delete-list {

      padding: 0 16px;

      .left-list {
        flex-wrap: nowrap;
        width: 250px;
        overflow-x: auto;
        padding: 16px 0 0;

        ::v-deep {
          .van-badge__wrapper {
            margin-right: 10px
          }
        }

        .wrap-left {
          width: 36px;
          height: 36px;
        }

        .user-name {
          font-size: 12px;
          color: #6d7278;
          word-break: keep-all;
        }
      }
    }

    .delete-button {
      width: 108px;
      height: 38px;
      border-radius: 100px;
      font-size: 13px;
      color: #fff;
      margin-top: 16px;
      background-color: #888888;
    }

    .baColor {
      background-color: $mainColor;
    }
  }
}
</style>
